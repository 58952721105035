import { Notifier } from "@airbrake/browser"

const airbrake = new Notifier({
  projectId: parseInt(process.env.AIRBRAKE_PROJECT_ID),
  projectKey: process.env.AIRBRAKE_PROJECT_KEY,
  host: process.env.AIRBRAKE_HOST,
  remoteConfig: false,
  performanceStats: false,
  queryStats: false,
  queueStats: false,
})

airbrake.addFilter(function (notice) {
  notice.context.environment = process.env.RAILS_ENV
  notice.context.user = { id: String(window.per_angusta.current_user.id) }
  notice.params.tenant = window.per_angusta.current_tenant.subdomain
  notice.params.stack = "JavaScript"

  return notice
})

const hidden_alerts = [
  "syntax error (https://script.hotjar.com/modules", // TODO: remove once hotjar fix this error
  "Object Not Found Matching Id:", // It appears this is from some password manager browser extension
  "Could not load Userflow.js", // When, for certain reasons (like network blocking, etc.), Userflow fails to load, it triggers an error
  "Failed to execute 'postMessage' on 'Window': #<Window> could not be cloned", // It seems to be a browser extension...
  window.per_angusta.i18n.translations.assets.error_messages.max_files_count, // Hide max upload count error.
  "Talisman extension has not been configured yet", // It seems to be a browser extension...
]

hidden_alerts.forEach((hidden_alert) => {
  airbrake.addFilter((notice) => {
    if (notice?.errors?.[0]?.message?.includes(hidden_alert)) {
      return null
    }

    return notice
  })
})

window.onerror = function (message, source, line_number, column_number, error) {
  airbrake.notify(error).then(function (notice) {
    if (notice.id) {
      console.log("SpendHQ PPM team have been notified about the below error")
    } else {
      console.log("/!\\ : An issue has been encountered: The below javascript error could not have been send to SpendHQ PPM team.")
    }
  })
}
